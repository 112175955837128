(function($)
{
    jQuery.fn.showWidgetLinkInfo = function(infoText)
    {
        const linkInfoTemplate = jQuery("#widgetInfoLinkTemplate").html();
        $(this).each(function() {
            const _info = jQuery(linkInfoTemplate).insertAfter(jQuery(this));
            _info.attr("data-bs-content", infoText);
            new bootstrap.Popover(_info, {
                trigger: "hover click"
            });
        });
    };
})(jQuery);
