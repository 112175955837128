/**
 * @function overwrite ITCs "showWidgetContent" function
 * @param {String} widgetId
 * @param {String} prefix
 * @param details
 * @param {String} notAvailableMessage
 */
function showWidgetContent(widgetId, prefix, details, notAvailableMessage) {
    const _loader = jQuery("#" + widgetId + "Widget .osp-card-loader");
    const _widget = jQuery("#" + widgetId + "Widget");
    const _noData = jQuery("#" + widgetId + "WidgetNoData");
    const _content = jQuery("#" + widgetId + "Content");
    const _content1 = jQuery("#" + widgetId + "Content1");
    _loader.fadeOut(250, function () {
        _loader.removeClass("show");
        _widget.fadeIn(250, function () {
            _widget.addClass(
                $("#" + widgetId + "AdditionalClass").attr("class")
            );
        });

        if (notAvailableMessage === undefined) {
            $("#" + widgetId + "WidgetContent")
                .addClass("d-md-block")
                .removeClass("hide");
            _widget
                .find(".card-footer")
                .addClass("d-md-block")
                .removeClass("hide")
                .detach()
                .appendTo("#" + widgetId + "Widget");
        } else {
            _noData.find(".widget-loading-container").text(notAvailableMessage);
            _noData.removeClass("hide");
        }
    });
    if (details === undefined) {
        if (_content1.length > 0) {
            details =
                _content1.data("DETAILS") || _content1.hasClass("hasDetails");
        } else if (_content.length > 0) {
            details =
                _content.data("DETAILS") || _content.hasClass("hasDetails");
        } else {
            details = false;
        }
    }
    if (details) {
        $("#" + prefix + "DetailsVisible").show();
    } else {
        $("#" + prefix + "DetailsVisible").hide();
    }
}
