/**
 * @function filter unwanted options
 * @param {jQuery} _el
 * @returns {jQuery}
 * @constructor
 */
const IVUFilterOptions = (_el) => {
    const valueToRemove = [
        "3_Hausgemeinschaft",
        "3_Erbengemeinschaft",
        "1_Herrn und Frau"
    ];
    return _el.filter( function(index, option) {
        const displayValue = option.innerHTML;
        const value = option.value;
        const shouldBeRemoved = jQuery.inArray(value, valueToRemove) > -1;
        if (displayValue.indexOf("_") !== 1 && !shouldBeRemoved) {
            return option;
        }
    });
}

/**
 * @function handle option sorting of a specific select
 * @param {jQuery} _el
 * @constructor
 */
const IVUSortSelectOptions = (_el) => {
    const hasSelectOption = _el.find("option[value=\"\"]").length > 0;
    const selectedOption = _el.val();
    // prepare options and filter options that we don't need/want
    let options = IVUFilterOptions(_el.find("option[value!=\"\"]"));
    options = options.sort(function(a,b) {
        if (a.text.toLowerCase() > b.text.toLowerCase()) return 1;
        else if (a.text.toLowerCase() < b.text.toLowerCase()) return -1;
        else return 0;
    });
    // empty select and prepare new dom
    _el.empty();
    if (hasSelectOption) {
        _el.append("<option value=\"\">Bitte auswählen</option>");
    }
    _el.append( options );
    if (selectedOption) {
        _el.val(selectedOption);
    } else {
        _el.val("");
    }
    _el.selectpicker("refresh");
    console.log("sorted #" + _el.attr("id"));
}

/**
 * @function filter selects and call sorting of specific selects
 * @constructor
 */
const IVUHandleSortSelects = () => {
    let _selects = jQuery("select.selectpicker");
    _selects = _selects.filter( function() {
        const id = jQuery(this).attr("id");
        return id.toLowerCase().indexOf("date") === -1;
    });
    if (_selects.length === 0) return;
    console.log("sorting " + _selects.length + " selects:");
    _selects.each( function() {
        IVUSortSelectOptions(jQuery(this));
    })
}

