/**
 * @function overwrite ITCs "showWidgetFieldError" function
 * @param {String} id
 * @param {String} message
 * @param {Boolean} focus
 */
function showWidgetFieldError(id, message, focus) {
    var field = $("#" + id);
    field.addClass("alert-danger");
    var inputGroup = null;
    if (field.parent().hasClass("form-floating")) {
        inputGroup = field.parent().parent();
    } else {
        inputGroup = field.parent();
    }
    inputGroup.parent().find("span.alert-info").remove();
    inputGroup
        // .addClass("input-group")
        .find("label,input")
        .addClass("alert-danger");
    //    inputGroup.append('<span class="input-group-text alert-danger" data-bs-toggle="popover"' +
    //        'data-bs-custom-class="alert-danger" data-bs-html="true" data-bs-content="' + message + '"><i class="fa fa-question-circle"></i></span>');
    inputGroup.append(
        '<span class="osp-card-error" data-bs-toggle="popover" data-bs-content="' +
            message +
            '"><svg class="osp-icon"><use xlink:href="/powercommerce/ivu/fo/portal/resources/images/i18n/iconsprite.svg#error"></use></svg></span>'
    );
    initWidgetErrorPopover(field);
    if (focus == undefined || focus) {
        focusFirstInputError();
    }
}
