/**
 * IVU JS Entrypoint
 */

numeral.register('locale', 'de', {
    delimiters: {
        thousands: '.',
        decimal: ','
    },
    abbreviations: {
        thousand: 'k',
        million: 'm',
        billion: 'b',
        trillion: 't'
    },
    ordinal : function (number) {
        return '.';
    },
    currency: {
        symbol: '€'
    }
});

/**
 * bootstrapping
 */
jQuery(document).ready(function() {
    IVUHandleSortSelects();
    console.log("ivu bootstrap done.");
});
