/**
 * @function overwrite ITCs "showWidgetMessage" function
 * @param id
 * @param type
 * @param message
 */
function showWidgetMessage(id, type, message) {
    var containsLink =
        message.indexOf("<a") > -1 &&
        message.indexOf("href") > -1 &&
        message.indexOf("</a>") > -1;
    var widgetForm = $("#" + id + "Widget form");
    var messageTitle = null;
    if (type == "info") {
        messageTitle = infoTitle;
    } else if (type == "warning") {
        messageTitle = warningTitle;
    } else {
        messageTitle = errorTitle;
    }
    var errorIcon =
        '<svg class="osp-icon" aria-hidden="true">' +
        '<use xlink:href="/powercommerce/ivu/fo/portal/resources/images/i18n/iconsprite.svg#error" />' +
        "</svg>";
    let tpl =
        '<div id="' +
        id +
        'Message" class="alert alert-' +
        (type == "error" ? "danger" : "info") +
        " fade " +
        (containsLink ? "" : "alert-dismissible") +
        '">' +
        '<h3 class="alert-heading">' +
        (type === "error" ? errorIcon : "") +
        messageTitle +
        "</h3>" +
        '<button type="button" class="btn btn-close" data-bs-dismiss="alert" title="Schließen">' +
        '<svg class="osp-icon osp-icon--small" aria-hidden="true">' +
        '<use xlink:href="/powercommerce/ivu/fo/portal/resources/images/i18n/iconsprite.svg#cancel" />' +
        "</svg>" +
        "</button>" +
        "<div>" +
        message +
        "</div></div>";
    widgetForm.append(tpl);
    setTimeout(function () {
        $("#" + id + "Message").addClass("show");
    }, 100);
}
